import React from 'react'
import PropTypes from 'prop-types'
import { base, psText, slider, tileContainer } from './ProgramSlider.styles'
import Container from '../../components/Container/Container'
import Tile from '../../components/Tile/Tile'

const ProgramSlider = ({ title, description, tiles }) => (
  <Container variant="fullWidth" css={base}>
    <div css={psText.container}>
      <h2 css={psText.heading}>{title}</h2>
      <p>{description}</p>
    </div>
    <div css={slider}>
      {tiles.map((child, index) => {
        if (index === 0)
          return (
            <div key={index} css={tileContainer.first}>
              <Tile title={child.title} text={child.description} image={child.image} link={child.link?.url} />
            </div>
          )
        if (index === tiles.length - 1)
          return (
            <div key={index} css={tileContainer.last}>
              <Tile title={child.title} text={child.description} image={child.image} link={child.link?.url} />
            </div>
          )
        return (
          <div key={index} css={tileContainer.tile}>
            <Tile title={child.title} text={child.description ? child.description : null} image={child.image} link={child.link?.url} />
          </div>
        )
      })}
    </div>
  </Container>
)

ProgramSlider.propTypes = {
  tiles: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.node]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}

ProgramSlider.defaultProps = {}

export default ProgramSlider
