import tw, { css } from 'twin.macro'

export const wrapper = [tw`my-10 flex flex-col tablet:my-5`]

export const text = {
  base: tw`mb-10 flex flex-col items-start`,
  heading: tw`text-purple-400 mb-2`,
}

export const tileContainer = [
  css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  `,
  tw`gap-y-8 gap-x-4 phone:!grid-cols-1`,
]

export const buttonStyle = tw`my-10 mx-auto`
