import React from 'react'
import PropTypes from 'prop-types'
import { wrapper, text, tileContainer, buttonStyle } from './BlogFeed.styles'
import BlogTile from '../../components/BlogTile/BlogTile'
import Wysiwyg from '../../components/Wysiwyg/Wysiwyg'
import Container from '../../components/Container/Container'
import Button from '../../components/Button/Button'

function BlogFeed({ title, description, blogPosts, button, buttonLink, buttonText }) {
  let parsedLink
  if (buttonLink) {
    // need to harden this
    parsedLink = new URL(buttonLink.url)
  }
  return (
    <Container variant="wide" css={wrapper}>
      <div css={text.base}>
        <h2 css={text.title}>{title}</h2>
        <Wysiwyg>{description}</Wysiwyg>
      </div>
      <div css={blogPosts && tileContainer}>
        {blogPosts ? (
          <>
            {blogPosts.map(post => (
              <BlogTile
                key={post.id}
                title={post.title}
                date={post.date}
                // // length={post.length}
                image={post.featuredImage && post.featuredImage.node}
                link={post.uri}
                category={post.categories?.nodes[0]?.name}
              />
            ))}
          </>
        ) : (
          <div tw="border border-gray-100 my-4 p-4 rounded shadow-lg flex justify-center items-center">
            <h3 tw="font-bold p-0 m-0 text-xl">
              <span tw="mr-1">🤦‍♂️ Oops... No blog posts were selected</span>
            </h3>
          </div>
        )}
      </div>
      {button && (
        <Button href={parsedLink?.pathname} css={buttonStyle} variant="primary" size="large">
          {buttonText}
        </Button>
      )}
    </Container>
  )
}

BlogFeed.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.object,
  blogPosts: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.node]),
}

export default BlogFeed
