import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from '@googlemaps/react-wrapper'
import { ArrowLongRightIcon } from '@heroicons/react/24/solid'
import { Link, navigate } from 'gatsby'
import Autocomplete from '../../components/Autocomplete/Autocomplete'
import { icon, form, link } from './LocationCta.styles'
import Cta from '../Cta/Cta'
import Button from '../../components/Button/Button'

function CtaChildren({ inputLabel }) {
  const [isDisabled, setIsDisabled] = useState(false)

  const handleAutocomplete = coords => {
    if (coords && coords.lat && coords.lng) {
      navigate('/locations', { state: { coords } })
    }
  }

  return (
    <div>
      <p css={form.label}>
        <strong>{inputLabel}</strong>
      </p>
      <div css={form.container}>
        <Wrapper apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY} libraries={['places']}>
          <Autocomplete tw="pl-4" css={form.input} onUpdate={handleAutocomplete} hasValidInput={val => setIsDisabled(!val)} />
        </Wrapper>
        <Button href="/locations" size="input" disabled={isDisabled} type="submit" css={form.button} variant="primary">
          Search
        </Button>
      </div>
      <Link css={link} to="/locations" state={{ fromHero: true }}>
        or view all locations
        <ArrowLongRightIcon css={icon} />
      </Link>
    </div>
  )
}

function LocationCta({ title, text, imageUrl, template, inputLabel }) {
  return (
    <Cta title={title} text={text} image={imageUrl} size="full" template={template}>
      <CtaChildren inputLabel={inputLabel} />
    </Cta>
  )
}

CtaChildren.propTypes = {
  inputLabel: PropTypes.string,
}

LocationCta.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  inputLabel: PropTypes.string,
  imageUrl: PropTypes.object,
  template: PropTypes.oneOf(['green', 'purple']),
}

export default LocationCta
