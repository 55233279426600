import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { tile, tileImage, tileText } from './Tile.styles'
import Container from '../Container'

function TileComponent({ title, text, image }) {
  const imageObject = getImage(image.gatsbyImage)
  return (
    <Container css={[tile.base, tile.shadow, tile.size]}>
      {imageObject && (
        <GatsbyImage
          image={imageObject}
          alt={image.altText}
          css={tileImage}
          imgStyle={{ objectPosition: `0px 20%`, borderTopLeftRadius: `1.5rem`, borderTopRightRadius: `1.5rem` }}
        />
      )}
      <div css={tileText.container}>
        <h3 css={tileText.heading}>{title}</h3>
        <p>{text}</p>
      </div>
    </Container>
  )
}

function Tile({ title, text, image, link }) {
  if (link) {
    const parsedLink = new URL(link)
    return (
      <Link to={link.startsWith('https://marketing.umbrellafamily') ? parsedLink.pathname : link}>
        <TileComponent title={title} text={text} image={image} />
      </Link>
    )
  }

  return <TileComponent title={title} text={text} image={image} />
}

TileComponent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.object.isRequired,
}

Tile.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.object.isRequired,
  link: PropTypes.string,
}

export default Tile
