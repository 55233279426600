import tw, { css } from 'twin.macro'

export const tile = {
  base: tw`bg-white text-left p-0 mb-4 rounded-3xl transition-all duration-150 outline-none`,
  shadow: css`
    box-shadow: 8px 8px 30px 5px #81318f20;
  `,
  size: css`
    width: 343px;
  `,
}

export const tileImage = tw`p-0 !overflow-hidden rounded-t-3xl w-full h-[205px]`

export const tileText = {
  container: tw`px-8 py-5`,
  heading: tw`text-purple-400 text-[24px] m-0 pb-1`,
}
