import tw, { css } from 'twin.macro'

export const form = {
  container: tw`flex mt-3 mb-3 phablet:flex-col`,
  label: tw`text-sm pb-0`,
  input: tw`w-full text-black`,
  button: tw`ml-5 phablet:ml-0 phablet:mt-3`,
}

export const icon = tw`h-4 w-4 ml-2 inline-block`

export const link = tw`mt-3 font-sans font-bold text-sm text-white`
