import tw, { styled, css } from 'twin.macro'

export const base = tw`py-10 bg-transparent transition-all duration-150 outline-none tablet:py-5`

export const psText = {
  container: tw`text-center mb-10 max-w-7xl w-4/5 flex flex-col mx-auto phone:w-full phone:px-6`,
  heading: tw`my-3`,
}

export const slider = tw`flex overflow-x-scroll`

export const tileContainer = {
  first: tw`ml-auto justify-start pl-8 my-5 mr-5`,
  last: tw`mr-auto pr-8 my-5 ml-5`,
  tile: tw`m-5`,
}
